import ProfilePic from '../../assests/profile-pic.png'
import '../../sass/components/hero.scss'
import {Link} from "react-router-dom";


const Hero = ()=>{

    return(
        <section className={'hero-section'}>
            <div className={'container'}>
                <div className={'hero-section-wrapper'}>
                    <div className={'image'}>
                        <img src={ProfilePic} alt={'profile'} className={'profile'} loading={'lazy'} height={'100%'}
                             width={'100%'}/>
                    </div>
                    <div className={'info'}>
                        <h1 className={'name'}>Sahil Pate</h1>
                        <p className={'designation'}>Certified Bigcommerce | Shopify | Next Js | React Js | Frontend
                            Developer</p>
                        <Link to={'mailto:connect@sahilpate.dev'} className={'resumeButton'}>
                            Get in touch
                        </Link>

                    </div>
                </div>
            </div>
            <div className="embed-animated-text-background" >
                <span className="animate-text">
                    BigCommerce
                </span>
                <span className="animate-text">
                    WordPress
                </span>
                <span className="animate-text">
                    React Js
                </span>
                <span className="animate-text">
                    Shopify
                </span>
                <span className="animate-text">
                    Node Js
                </span>
                <span className="animate-text">
                    Mongodb
                </span>
            </div>
        </section>
    )
}
export default Hero;
